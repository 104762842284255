.btn:disabled {
  background-color: var(--system-grey-2);
  border-color: var(--system-grey-2);
  opacity: 1;
}

.form-label {
  font-family: 'lato', sans-serif;
  font-size: 14px;
  line-height: 17px;
}

.was-validated {
  .form-control {
    border: 1px solid var(--system-grey-2);
    border-radius: 6px;

    &:invalid,
    &.is-invalid {
      z-index: 1;
      border-color: var(--bs-danger) !important;
      & + .invalid-feedback {
        background-color: var(--bs-danger);
        color: white;
        padding: 8px 8px 4px 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        width: 100%;
        display: inline-block;
        margin: 0 auto;
        transform: translateY(-4px);
      }
    }
  }
}
