.AppPage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .AppPageBody {
    flex-grow: 1;
  }
}


