.headerWrapper {
  .headerContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .header {
    display: flex;
    padding: 0 40px;
    justify-content: space-between;
    background-color: var(--app-white);
    border-bottom: 1px solid var(--app-black-20);
  }

  .headerLeft {
    gap: 40px;
    display: flex;
    align-items: center;
  }

  .headerMenuIcon {
    padding: 8px;
    display: none;
    cursor: pointer;
  }

  .headerLogo {
    width: 160px;
    cursor: pointer;
    margin-bottom: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .navigation {
    gap: 24px;
    display: flex;
    align-self: stretch;
  }

  .navigationItem {
    gap: 4px;
    height: 100%;
    cursor: pointer;
    position: relative;
    align-items: center;
    display: inline-flex;

    .navigationItemLabel {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--app-black-70);
      font-family: "Inter", sans-serif;
    }

    .navigationItemArrow {
      transition: 0.2s transform ease;
    }

    .navigationSubmenu {
      left: 0;
      gap: 8px;
      z-index: 1;
      display: none;
      padding: 8px;
      min-width: 225px;
      position: absolute;
      top: calc(50% + 12px);
      border-radius: 16px;
      flex-direction: column;
      background: var(--app-white);
      box-shadow: 0 16px 64px rgba(0, 0, 0, 0.12);

      .navigationSubmenuItem {
        gap: 16px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 10px 18px;
        background-color: var(--app-white);
        transition: 0.3s background-color ease;

        .navigationSubmenuItemIcon {
          svg {
            fill: var(--app-black-100);
            transition: 0.3s fill ease;
          }
        }

        .navigationSubmenuItemLabel {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: var(--app-black-70);
          transition: 0.3s color ease;
          font-family: "Inter", sans-serif;
        }

        &.navigationSubmenuItemActive {
          .navigationSubmenuItemIcon {
            svg {
              fill: var(--app-blue-50);
            }
          }

          .navigationSubmenuItemLabel {
            color: var(--app-blue-50);
          }
        }

        &:hover {
          background-color: var(--app-blue-10);

          .navigationSubmenuItemIcon {
            svg {
              fill: var(--app-blue-50);
            }
          }

          .navigationSubmenuItemLabel {
            color: var(--app-blue-50);
          }
        }
      }
    }

    &.navigationItemOpenedSubmenu {
      .navigationItemArrow {
        transform: scaleY(-1);
      }

      .navigationSubmenu {
        display: flex;
      }
    }

    &.navigationItemActive {
      &::after {
        content: '';
        left: 0;
        right: 0;
        bottom: -1px;
        height: 4px;
        position: absolute;
        background-color: var(--app-blue-50);
      }

      .navigationItemLabel {
        color: var(--app-blue-50);
      }
    }
  }

  .headerRight {
    gap: 16px;
    display: flex;
    align-items: center;

    .headerRightMain {
      gap: 16px;
      display: flex;
      padding: 16px 0;
      align-items: center;
    }
  }

  .headerLink {
    gap: 8px;
    padding: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;

    .headerLinkIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .headerLinkLabel {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: var(--app-blue-50);
      font-family: "Inter", sans-serif;
    }
  }

  .profileLinkWrapper {
    height: 100%;

    .profileLinkAvatar {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
      object-position: center;
    }

    .navigationItemLabel {
      margin-left: 12px;
    }
  }

  .headerPadding {
    padding-top: 89px;
  }

  &.cookieBanner {
    .headerPadding {
      padding-top: 121px;
    }

    &.cookieBannerShown {
      .headerPadding {
        padding-top: 314px;
      }
    }
  }

  &.partnersBanner {
    .headerPadding {
      padding-top: 145px;
    }

    &.cookieBanner {
      .headerPadding {
        padding-top: 177px;
      }

      &.cookieBannerShown {
        .headerPadding {
          padding-top: 370px;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .headerWrapper {
    .headerLeft {
      gap: 24px;
    }

    .headerMenuIcon {
      display: inline-block;
    }

    .navigation {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .headerWrapper {
    .header {
      padding: 0 32px;
    }

    .headerLeft {
      gap: 8px;
    }

    .headerRight {
      gap: 8px;

      .headerRightMain {
        gap: 8px;
        padding: 8px 0;
      }
    }

    .profileLinkWrapper {
      .profileLinkAvatar {
        width: 32px;
        height: 32px;
      }

      .navigationItemLabel {
        margin-left: 0;
      }
    }

    .headerPadding {
      padding-top: 57px;
    }

    &.cookieBanner {
      .headerPadding {
        padding-top: 89px;
      }

      &.cookieBannerShown {
        .headerPadding {
          padding-top: 282px;
        }
      }
    }

    &.partnersBanner {
      .headerPadding {
        padding-top: 113px;
      }

      &.cookieBanner {
        .headerPadding {
          padding-top: 145px;
        }

        &.cookieBannerShown {
          .headerPadding {
            padding-top: 338px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .headerWrapper {
    .header {
      padding: 0 8px;
    }

    .headerLink {
      &.headerLinkBounty {
        display: none;
      }
    }

    &.partnersBanner {
      .headerPadding {
        padding-top: 105px;
      }

      &.cookieBanner {
        .headerPadding {
          padding-top: 137px;
        }

        &.cookieBannerShown {
          .headerPadding {
            padding-top: 330px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .headerWrapper {
    .headerLink {
      display: none;
    }

    .profileLinkWrapper {
      min-height: 56px;
    }

    .translate {
      display: none;
    }

    .loginButton {
      display: none;
    }
  }
}



