.mcFieldGroup {
  gap: 16px;
  width: 100%;
  display: flex;
  margin: 0 auto;

  .mcEmail {
    flex-grow: 1;
  }

  .mcEmbeddedSubscribe {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 767px) {
  .mcFieldGroup {
    flex-direction: column;
  }
}
