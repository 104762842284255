.enableCookies {
  display: flex;
  min-height: 32px;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--app-black-10);
  background-color: var(--app-white);
  transition: 0.3s background-color ease-in-out;

  .enableCookiesSmall {
    .title {
      margin: 0;
      padding-left: 32px;
      padding-right: 32px;
      width: 100%;
      font-size: 13px;
      font-weight: 400;
      text-align: center;
      color: var(--app-black-100);
      font-family: "Inter", sans-serif;
    }
  }

  .enableCookiesBig {
    display: flex;
    height: 224px;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;

    .cookie {
      margin-bottom: 24px;
    }

    .information {
      .title {
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        color: var(--app-black-100);
        font-family: "Inter", sans-serif;

        .highLight {
          color: var(--app-red-50);
        }
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: var(--app-black-100);
        font-family: "Inter", sans-serif;
      }
    }
  }

  .toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 32px;
    cursor: pointer;
    padding: 4px 8px;
    color: var(--app-black-100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s 0.2s width ease-in-out;

    .toggleIcon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      transform: rotate(180deg);
      display: inline-flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .toggleText {
      font-size: 0;
      opacity: 0;
      color: var(--app-black-50);
      overflow: hidden;
      display: inline-block;
      transition: 0.2s 0.2s padding-right ease-in-out, 0.2s 0.2s opacity ease-in-out, 0.2s 0.3s font-size ease-in-out;
    }

    &:hover {
      width: 74px;
      transition: 0.2s width ease-in-out;

      .toggleText {
        opacity: 1;
        font-size: 13px;
        padding-right: 8px;
        transition: 0.2s padding-right ease-in-out, 0.2s opacity ease-in-out, 0.2s font-size ease-in-out;
      }
    }
  }

  .collapse {
    width: 100%;
  }

  &.show {
    .toggleIcon {
      transform: rotate(0deg);
    }
  }
}

@media screen and (max-width: 767px) {
  .enableCookies {
    .enableCookiesBig {
      .information {
        .title {
          font-size: 24px;
        }

        .description {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .enableCookies {
    .enableCookiesSmall {
      .title {
        text-align: left;
        padding-left: 8px;
      }
    }

    .enableCookiesBig {
      .information {
        br {
          display: none;
        }

        .description {
          font-size: 13px;
        }
      }
    }
  }
}