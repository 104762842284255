@import '~bootstrap/scss/functions';

// $default-text-color: #000000;
$primary: #007aff;
$primary-light: #84dce4;
$secondary: #c7c7cc;
$success: #34c759;
$info: #13101c;
$warning: #945707;
$danger: #ff3b30;
$light: #f8f9fa;
$dark: #343a40;
$dark-gray: #131313;

$theme-colors: (
        primary: $primary,
        secondary: $secondary,
        success: $success,
        info: $info,
        warning: $warning,
        danger: $danger,
        light: $light,
        dark: $dark,
  // add any additional color below
);

@import '~bootstrap/scss/variables';

$position-values: (
        0: 0,
        1: 4px,
        2: 8px,
        3: 12px,
        50: 50%,
        100: 100%,
);

:root {
  --bs-body-font-family: 'Lato', sans-serif;
  // --bs-body-color: #{$default-text-color};
  --system-transparent: transparent;
  --system-primary: #007aff;
  --system-black: #000000;
  --system-white: #ffffff;
  --system-grey-1: #f2f2f7;
  --system-grey-2: #c7c7cc;
  --system-grey-3: #8a8a8e;
  --system-danger: #ff3b30;


  // New Colors
  --app-white: #ffffff;
  --app-yellow-100: #906C00;
  --app-yellow-70: #DBA300;
  --app-yellow-50: #FABC05;
  --app-yellow-30: #FCD769;
  --app-yellow-20: #FEEBB4;
  --app-yellow-10: #FFF8E6;

  --app-red-100: #6E0000;
  --app-red-70: #B00000;
  --app-red-50: #DC0000;
  --app-red-30: #E74D4D;
  --app-red-20: #EE8080;
  --app-red-10: #FCE6E6;

  --app-blue-100: #002247;
  --app-blue-70: #00448E;
  --app-blue-50: #0069DB;
  --app-blue-30: #66AAF4;
  --app-blue-20: #B3D4F9;
  --app-blue-15: #c7defa;
  --app-blue-10: #E6F1FD;

  --app-green-100: #022718;
  --app-green-70: #034E30;
  --app-green-50: #058250;
  --app-green-30: #69B496;
  --app-green-20: #B4DACB;
  --app-green-10: #E6F3EE;

  --app-black-100: #0B0B0C;
  --app-black-70: #434446;
  --app-black-50: #6F7274;
  --app-black-30: #A9AAAC;
  --app-black-20: #D4D5D5;
  --app-black-16: #d8d8d8;
  --app-black-10: #F1F1F1;
  --app-black-8: #ebebec;
  --app-black-4: #f5f5f6;
  // New Colors
}

.table-striped-even > tbody > tr:nth-child(even) > td,
.table-striped-even > tbody > tr:nth-child(even) > th {
  background-color: var(--system-grey-1);
}

//#region custome style for REACT-MODERN-CALENDAR-DATEPICKER library
// https://kiarash-z.github.io/react-modern-calendar-datepicker/
.Calendar__monthArrow {
  background-image: url('./assets/images/previous.svg') !important;
  transform: none !important;
}

.Calendar.-rtl .Calendar__monthArrowWrapper.-left,
.Calendar__monthArrowWrapper.-right {
  transform: rotate(0deg) !important;
}

.Calendar__monthArrowWrapper.-left {
  transform: rotate(180deg) !important;
}

.Calendar__weekDay {
  color: #000;
}

.Calendar__monthSelectorAnimationWrapper,
.Calendar__yearSelectorAnimationWrapper {
  height: 85% !important;
}

//#endregion

@import '~bootstrap/scss/bootstrap';
@import 'styles/general';
@import 'styles/overrides';
@import 'styles/typography';
@import 'styles/cards';
@import 'styles/forms';
@import 'react-toastify/dist/ReactToastify.css';
@import '~/node_modules/placeholder-loading/src/scss/placeholder-loading';


.grecaptcha-badge {
  visibility: hidden;
}

.airdrop-card {
  padding: 40px;
  border-radius: 24px;
  background: var(--app-white);
  box-shadow: 0 12px 80px rgba(0, 0, 0, 0.16);
}
