.inputWrapper {
  --disable: #ebebeb;
  --disable-text: var(--app-black-30);

  --white: var(--app-white);
  --white-border: var(--app-black-20);

  --gray: #f5f5f5;
  --gray-border: var(--app-black-30);

  width: 100%;
  user-select: none;

  .inputContainer {
    width: 100%;
    display: flex;
    padding: 9px 16px;
    border-radius: 12px;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s box-shadow ease-in-out;

    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      svg {
        width: 24px;
        height: 24px;
        fill: var(--app-black-70)
      }
    }

    .inputMain {
      flex-grow: 1;
      height: 100%;
      position: relative;

      .input {
        padding: 0;
        width: 100%;
        border: none;
        outline: none;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        overflow: hidden;
        margin-top: 14px;
        text-overflow: ellipsis;
        color: var(--app-black-100);
        background-color: transparent;
        font-family: "Inter", sans-serif;

        &::placeholder {
          opacity: 0;
        }

        &:focus,
        &:not(:placeholder-shown) {
          + .floatingPlaceholder {
            top: 0;
            font-size: 12px;
            line-height: 14px;
            transform: translateY(0px);
          }
        }

        &:placeholder-shown {
          ~ .clearIcon {
            display: none;
          }
        }
      }

      .floatingPlaceholder {
        width: 100%;
        top: 50%;
        left: 0;
        font-size: 16px;
        font-weight: 500;
        position: absolute;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        pointer-events: none;
        color: var(--app-black-70);
        transform: translateY(-50%);
        transition: 0.2s all ease-in-out;

        .asterisk {
          color: var(--app-red-50);
        }
      }

      .clearIcon {
        top: 50%;
        right: 0;
        cursor: pointer;
        position: absolute;
        transform: translateY(-50%);

        svg {
          width: 24px;
          height: 24px;
          fill: var(--app-black-70)
        }

        &:hover {
          svg {
            fill: var(--app-black-100);
          }
        }
      }

      .eyeIcon {
        top: 50%;
        right: 0;
        cursor: pointer;
        position: absolute;
        transform: translateY(-50%);

        svg {
          width: 24px;
          height: 24px;
          fill: var(--app-black-70)
        }

        &:hover {
          svg {
            fill: var(--app-black-100);
          }
        }
      }
    }

    &:hover {
      &.white {
        box-shadow: 0 0 0 1px var(--white-border);
      }

      &.gray {
        box-shadow: 0 0 0 1px var(--gray-border);
      }
    }

    &.clearable {
      .inputMain {
        .input {
          padding-right: 32px;
        }

        .floatingPlaceholder {
          padding-right: 32px;
        }
      }
    }

    &.focused {
      &.white,
      &.gray {
        border-color: var(--app-blue-50);
        box-shadow: 0 0 0 1px var(--app-blue-50);
      }
    }

    &.invalid {
      &.white,
      &.gray {
        border-color: var(--app-red-50);
        box-shadow: 0 0 0 1px var(--app-red-50);
      }

      .inputMain {
        .input {
          &:focus,
          &:not(:placeholder-shown) {
            + .floatingPlaceholder {
              color: var(--app-red-50);
            }
          }
        }
      }
    }

    &.disabled {
      cursor: not-allowed;

      .icon,
      .clearIcon {
        pointer-events: none;

        svg {
          fill: var(--disable-text)
        }
      }

      .inputMain {
        pointer-events: none;

        .input {
          color: var(--disable-text);
        }

        .floatingPlaceholder {
          color: var(--disable-text) !important;
        }
      }

      &.white,
      &.gray {
        border-color: var(--disable);
        background-color: var(--disable);
        box-shadow: 0 0 0 1px var(--disable);
      }
    }

    &.noPlaceholder {
      .input {
        margin-top: 7px;
        margin-bottom: 7px;
      }
    }
  }

  .white {
    background-color: var(--white);
    border: 1px solid var(--white-border);

    .input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 56px var(--white) inset !important;
      }
    }
  }

  .gray {
    background: var(--gray);
    border: 1px solid var(--app-black-30);

    .input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 56px var(--gray) inset !important;
      }
    }
  }

  .invalidMessage {
    font-size: 12px;
    font-weight: 500;
    color: var(--app-red-50);
    margin-top: 4px;
    margin-left: 16px;
    font-family: 'Inter', sans-serif;
  }
}