.card-title-section {
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  font-style: bold;
  font-family: 'Lato', sans-serif;

  &.big {
    font-size: 24px;
  }
}

.card-title-section-left {
  @extend .card-title-section;
  text-align: left;
}

.scrollable-contract {
  max-height: 500px;
  overflow-y: scroll;
  &::-webkit-scrollbar-thumb {
    max-height: 10px;
    border-radius: 2px;
    border: 4px solid #c7c7cc;
  }

  &::-webkit-scrollbar {
    width: 4px;
    max-height: 10px;
  }
}

.card-page {
  margin-top: 10vh;
  box-shadow: 0px 2px 5px rgba(120, 143, 164, 0.5);
  border-radius: 8px;
  border: none;

  .card-title {
    padding: 32px 32px 0 32px;
  }
  .card-body {
    padding: 32px;
  }
}

@include media-breakpoint-down(sm) {
  .card-page {
    margin: 0;
    border-radius: none;
    box-shadow: none;

    .card-title {
      background-color: var(--system-grey-1);
      padding: 12px;
      text-align: center;
    }

    .card-body {
      padding: 12px;
    }
  }
}
