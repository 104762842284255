.footer {
  padding: 40px;
  border-top: 1px solid var(--app-black-20);
  background-color: var(--app-white);
}

.footerMain {
  gap: 40px;
  display: flex;
  padding-bottom: 40px;
  align-items: flex-start;
  justify-content: space-between;

  .info {
    width: 700px;
    flex-shrink: 1.5;

    .footerLogo {
      width: 160px;
      cursor: pointer;
      margin-bottom: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .information {
      gap: 24px;
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;

      .informationText {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: var(--app-black-100);
        font-family: "Inter", sans-serif;
      }
    }

    .socials {
      gap: 24px;
      display: flex;
      align-items: center;

      .footerLink {
        gap: 8px;
        padding: 8px;
        display: flex;
        cursor: pointer;
        align-items: center;
      }
    }
  }

  .links {
    width: 280px;

    .linksInner {
      gap: 16px;
      display: flex;
      flex-wrap: wrap;

      .link {
        width: calc(50% - 8px);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        font-family: "Inter", sans-serif;
        color: var(--app-black-100)
      }
    }
  }

  .newsletter {
    width: 700px;

    .newsletterText {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: var(--app-black-100);
      font-family: "Inter", sans-serif;
    }

    .reCaptchaInfoContainer {
      display: flex;
      margin-top: 4px;

      span {
        text-align: left;
      }
    }

    .newsletterInfo {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      margin-bottom: 0;
      color: var(--app-black-50);
      font-family: "Inter", sans-serif;
    }
  }
}

.footerBottom {
  padding-top: 40px;
  border-top: 1px solid var(--app-black-20);

  .footerBottomInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .copyRight {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Inter", sans-serif;
    color: var(--app-black-100);
  }

  .privacy {
    display: flex;
    align-items: center;

    .privacyLink {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-family: "Inter", sans-serif;
      text-decoration: underline;
      color: var(--app-blue-50);

      &:not(:last-child) {
        &::after {
          content: '';
          display: inline-block;
          width: 5px;
          height: 5px;
          margin: 0 8px;
          border-radius: 50%;
          background-color: var(--app-black-50);
        }
      }
    }
  }

  .googleTranslate {
    height: 24px;

    img {
      height: 100%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .footerMain {
    flex-wrap: wrap;

    .info {
      width: calc(60% - 40px);
      flex-shrink: 1;
    }

    .links {
      width: 40%;
    }

    .newsletter {
      width: 100%;
      padding-top: 40px;
      border-top: 1px solid var(--app-black-20);
    }
  }
}

@media screen and (max-width: 991px) {
  .footer {
    padding: 32px 32px 40px;
  }

  .footerMain {
    gap: 24px;
    padding-bottom: 24px;

    .newsletter {
      padding-top: 24px;
    }
  }

  .footerBottom {
    padding-top: 24px;

    .footerBottomInner {
      gap: 24px;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 767px) {
  .footer {
    padding: 24px 16px 40px;
  }

  .footerMain {
    flex-wrap: wrap;

    .info {
      width: 100%;
    }

    .links {
      width: 100%;
      padding-top: 40px;
      border-top: 1px solid var(--app-black-20);

      .linksInner {
        width: 280px;
      }
    }
  }
}