.wrapper {
  &.success {
    .hero {
      background-image: linear-gradient(to bottom, #E6F3EE, #B4DACB);

      .heroHeaderTop {
        display: none;
      }

      .container {
        .heroMain {
          padding-bottom: 144px;

          .title {
            color: var(--app-green-100);
          }

          .description {
            color: var(--app-green-100);
          }


          .status {
            svg {
              fill: var(--app-green-50);
            }
          }

          .lands {
            gap: 40px;
            display: flex;
            align-items: flex-start;

            .landsCardWrapper {
              gap: 8px;
              display: flex;
              align-items: flex-start;
              flex-direction: column;

              .landsCard {
                width: 439px;
                //min-height: 226px;
                display: flex;
                padding: 24px;
                flex-direction: column;
                border-radius: 12px;
                background: var(--app-white);

                .landsCardList {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;

                  .landsCardListScroll {
                    padding: 0 24px;
                    margin: 0 -24px;
                    height: 130px;
                    overflow: auto
                  }

                  .landsCardItem {
                    padding: 8px 0;
                    display: flex;
                    justify-content: space-between;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    font-family: 'Inter', sans-serif;
                    color: var(--app-black-100);

                    .landsCardItemName {

                    }

                    &.landsCardItemSummary {
                      font-weight: 700;
                    }
                  }

                  .landsCardSeparator {
                    width: 100%;
                    height: 1px;
                    margin: 8px 0;
                    background: rgba(11, 11, 12, 0.16);
                  }
                }
              }
            }

            .kycCard {
              width: 439px;
              min-height: 226px;
              display: flex;
              padding: 24px;
              flex-direction: column;
              justify-content: space-between;
              border-radius: 12px;
              background: var(--app-green-20);

              .kycCardTitle {
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                font-family: 'Inter', sans-serif;
                margin-bottom: 48px;
              }
            }

            .claimNowCardWrapper {
              gap: 8px;
              display: flex;
              flex-direction: column;

              .claimNowCard {
                width: 439px;
                display: flex;
                padding: 24px;
                min-height: 226px;
                flex-direction: column;
                justify-content: space-between;
                border-radius: 12px;
                background: var(--app-green-20);

                .claimNowCardTitle {
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 32px;
                  font-family: 'Inter', sans-serif;
                  margin-bottom: 24px;
                }
              }

              .addLandText {
                margin-bottom: 0;
              }
            }
          }

          .kycSuggestion {
            padding: 24px;
            margin-top: 16px;
            border-radius: 16px;
            background-color: var(--app-white);

            .kycSuggestionTitle {
              font-size: 16px;
              font-weight: 700;
              line-height: 20px;
              text-transform: uppercase;
              font-family: "Inter", sans-serif;
              color: var(--app-green-50);
              display: inline-block;
              margin-bottom: 16px;
            }

            .kycSuggestionDescription{
              font-size: 32px;
              font-weight: 700;
              line-height: 40px;
              font-family: "Inter", sans-serif;
              color: var(--app-black-100);
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    &:not(.claimed) {
      .claimCard {
        margin-bottom: 0;
      }
    }

    &.claimed {
      .claimCard {
        margin-bottom: 40px;
      }
    }
  }

  &.fail {
    .hero {
      background-image: linear-gradient(to bottom, #FFF8E6, #FEEBB4);

      .heroHeaderTop {
        display: none;
      }

      .container {
        .heroMain {
          .title {
            color: var(--app-yellow-100);
          }

          .description {
            color: var(--app-yellow-100);
            margin-bottom: 48px;
          }


          .status {
            svg {
              fill: var(--app-yellow-100);
            }
          }

          .receive {
            fill: var(--app-yellow-100);
            background: rgba(11, 11, 12, 0.04);
          }
        }
      }
    }
  }
}

.heroHeaderTop {
  gap: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto 38px;
  padding: 0 40px;
  max-width: 1480px;
}

.container {
  margin: 0 auto;
  padding: 0 40px;
  max-width: 1480px;
}

.hero {
  padding-top: 40px;
  background-image: linear-gradient(to bottom, #0069DB, #002247);
  overflow: hidden;

  .container {
    gap: 40px;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .heroMain {
      max-width: 920px;
      padding-bottom: 112px;

      .heroHeader {
        gap: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 38px;
      }

      .status {
        svg {
          width: 100px;
          height: 100px;
          fill: var(--app-green-50);
        }
      }

      .receive {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        padding: 8px 16px;
        display: inline-block;
        border-radius: 20px;
        fill: var(--app-green-100);
      }

      .title {
        font-size: 48px;
        font-weight: 700;
        line-height: 64px;
        font-family: "Inter", sans-serif;
        color: var(--app-white);
        margin-bottom: 40px;
        //margin-bottom: 24px;
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        font-family: "Inter", sans-serif;
        color: var(--app-white);
        margin-bottom: 40px;
        width: calc(100% - 10px);

        &:nth-of-type(1) {
          margin-bottom: 16px;
        }
      }

      .bold {
        font-weight: 700;
      }

      .help {
        cursor: pointer;
      }

      .favoredButton {
        margin-bottom: 48px;
      }

      .uniswap {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        padding: 8px 16px;
        border-radius: 20px;
        display: inline-block;
        color: var(--app-green-100);
        background-color: var(--app-white);
      }
    }

    .tulipImage {
      height: 632px;
      align-self: flex-end;
      margin: 0;

      img {
        height: 100%;
      }
    }
  }
}

.main {
  margin-top: -96px;
}

.video {
  width: 100%;
  max-width: 400px;
  margin-bottom: 40px;
  aspect-ratio: 1.77692307692;
  border-radius: 16px;
  overflow: hidden;
  text-align: left;
}

.card {
  z-index: 1;
  position: relative;
  border-radius: 24px;
  background: var(--app-white);
  box-shadow: 0 12px 80px rgba(0, 0, 0, 0.12);
  padding: 80px 120px;
  margin-bottom: 40px;

  .row {
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cardInfo {
      width: 50%;
      flex-shrink: 0.3;
    }

    .cardImage {
      width: 50%;
      margin: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }


    &.rowReverse {
      flex-direction: row-reverse;
    }
  }
}

.authCard {
  z-index: 1;
  position: relative;
  margin-bottom: 16px;
}

.claimCard {
  z-index: 1;
  position: relative;
  margin-bottom: 16px;
}

.contactBox {
  margin-bottom: 16px;
}

.walletActions {
  gap: 16px;
  display: flex;
  margin-top: 40px;
  justify-content: center;

  .walletInput {
    width: 100%;
    max-width: 502px;
    text-align: left;
  }

  .claimButton {
    flex-shrink: 0;
  }
}

.badges {
  gap: 16px;
  display: flex;
}

.badge {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 8px 16px;
  font-family: "Inter", sans-serif;
  color: var(--app-white);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.08);
}

.separator {
  width: 1px;
  height: 80px;
  background-color: var(--app-black-20);
  margin: 20px auto;
}

.cardTag {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  color: var(--app-blue-50);
  display: inline-block;
  margin-bottom: 16px;
}

.cardTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  font-family: "Inter", sans-serif;
  color: var(--app-black-100);
  margin-bottom: 24px;
}

.cardBadge {
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  border-radius: 12px;
  color: var(--app-blue-100);
  background: var(--app-blue-10);
  margin-bottom: 16px;
}

.cardDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  color: var(--app-black-100);
  margin-bottom: 16px;

  span {
    color: var(--app-blue-50);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.favoriteProjects {
  .favoriteProjectList {
    list-style: none;
    margin: 0;
    padding: 0;

    .favoriteProject {
      margin-bottom: 12px;
      line-height: 1.2;

      a {
        color: var(--app-black-100);
        transition: 0.1s color ease-in-out;

        &:hover {
          color: var(--app-blue-50);
        }
      }
    }
  }
}

.helpModalClassName {
  padding-right: 24px;
}

.helpModalHeaderClassName {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 1000px) {
  .wrapper {
    &.success {
      .hero {
        .container {
          .heroMain {
            padding-bottom: 0;

            .lands {
              flex-direction: column;

              .landsCardWrapper {
                width: 100%;

                .landsCard {
                  width: 100%;
                }
              }

              .kycCard {
                width: 100%;
              }

              .claimNowCardWrapper {
                width: 100%;

                .claimNowCard {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    &.fail {
      .hero {
        background-image: linear-gradient(to bottom, #FFF8E6, #FEEBB4);

        .container {
          .heroMain {
            .title {
              color: var(--app-yellow-100);
            }

            .description {
              color: var(--app-yellow-100);
            }


            .status {
              svg {
                fill: var(--app-yellow-100);
              }
            }

            .receive {
              fill: var(--app-yellow-100);
              background: rgba(11, 11, 12, 0.04);
            }
          }
        }
      }
    }
  }

  .hero {
    .container {
      flex-direction: column;

      .heroMain {
        padding-bottom: 0;

        .heroHeader {
          margin-bottom: 22px;
        }

        .title {
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 20px;
        }

        .description {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .tulipImage {
        width: 100%;
        margin-bottom: -218px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .main {
    margin-top: -50px;
  }

  .video {
    max-width: 100%;
  }

  .card {
    padding: 40px;

    .row {
      flex-direction: column-reverse;

      .cardInfo, .cardImage {
        width: 100%;
      }

      &.rowReverse {
        flex-direction: column-reverse;
      }
    }
  }

  .separator {
    margin: 40px auto;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    &.fail {
      .hero {
        background-image: linear-gradient(to bottom, #FFF8E6, #FEEBB4);

        .container {
          .heroMain {
            .title {
              color: var(--app-yellow-100);
            }

            .description {
              color: var(--app-yellow-100);
            }


            .status {
              svg {
                fill: var(--app-yellow-100);
              }
            }

            .receive {
              fill: var(--app-yellow-100);
              background: rgba(11, 11, 12, 0.04);
            }
          }
        }
      }
    }
  }

  .container {
    padding: 0 16px;
  }

  .hero {
    height: auto;

    .container {
      flex-direction: column;

      .heroMain {
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 24px;

        .heroHeader {
          gap: 16px;
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }

  .main {
    margin-top: -32px;
  }

  .card {
    padding: 16px;
    border-radius: 16px;
  }

  .cardTitle {
    font-size: 24px;
    line-height: 32px;
  }

  .walletActions {
    flex-direction: column;
  }

  .heroHeaderTop {
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  .badges {
    width: 100%;
    flex-direction: column;
  }

  .badge {
    flex-grow: 1;
    text-align: center;
  }
}

@media screen and (max-width: 360px) {
  .hero {
    .container {
      .heroMain {
        .uniswap {
          display: block;
        }
      }
    }
  }
}

