.google_translate_wrapper {
  position: relative;
  width: 48px;
  height: 56px;
  display: block;

  #google_translate_element {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    .google_translate_select {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      span {
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        line-height: 15px;
      }
    }

    .skiptranslate {
      height: 0;
      overflow: hidden;
      text-align: center;

      > div {
        opacity: 0;

        > select {
          position: absolute;
          left: 0;
          top: 0;
          height: 56px !important;
          width: 100%;
          opacity: 0;
          color: var(--app-black-100);
          margin: 0;
          cursor: pointer;
          border: none;

          > option:not(:first-of-type) {
            font-size: 16px;
            color: red;
          }
        }
      }
    }
  }
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body > .skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}

@media screen and (max-width: 991px) {
  .google_translate_wrapper {
    height: 40px;

    #google_translate_element {
      .skiptranslate {
        > div {
          > select {
            height: 40px !important;
          }
        }
      }
    }
  }

}
