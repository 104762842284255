.container {
  margin: 16px 0;

  .text {
    display: inline-block;
    font-family: "Lato", sans-serif;
    font-size: 9.6px;
    color: var(--system-grey-3);
    text-align: center;

    a{
      color: var(--system-primary);
      text-decoration: none;
    }
  }
}