/**
 * MainMenu specifics stylesheet
 */

.main-menu-container {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  pointer-events: none;

  .main-menu {
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 256px;
    height: 100vh;
    background-color: var(--system-white);
    transform: translateX(-256px);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 24px 16px;
    transition: 0.2s transform ease-in-out;
    overflow: auto;

    .main-menu-section {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      align-items: flex-start;

      .main-menu-login-button {
        margin-top: 24px;
        display: none !important;
      }

      .main-menu-list {
        list-style: none;
        padding: 0;
        margin: 24px 0 0;

        li {
          margin: 16px 0;

          &:first-child {
            margin-top: 0;
          }

          a {
            opacity: 1 !important;
            transform: none !important;
            color: var(--system-black);
            font-size: 20px;
            font-family: 'Lato', sans-serif;
            transition: 0.1s color ease-in-out;

            &:hover {
              color: var(--system-primary);
            }

            &.active {
              color: var(--system-primary);
            }
          }
        }
      }
    }

    .main-menu-footer {
      margin-top: auto;
      display: inline-flex;
      justify-content: space-between;

      .main-menu-footer-text {
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400;
      }

      .main-menu-footer-social {
        display: inline-block;

        a {
          cursor: pointer;
          margin-right: 24px;
          filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0));
          transition: 0.2s filter ease-in-out;

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.5));
          }
        }
      }
    }

    .translate {
      width: 100%;
      margin-top: auto;

      .google_translate_wrapper #google_translate_element .skiptranslate > div > select {
        height: 27px;
        -webkit-appearance: menulist-button;
      }
    }
  }

  .main-menu-overlay {
    flex-grow: 1;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: 0.2s background-color ease-in-out;
  }

  &.open {
    pointer-events: all;

    .main-menu {
      transform: translateX(0px);
    }

    .main-menu-overlay {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

@media screen and (max-width: 1199px) {
  .main-menu-container {
    display: flex;

    .main-menu {
      .main-menu-section {
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .main-menu-container {
    .main-menu {
      .main-menu-section {
        .main-menu-login-button {
          display: flex !important;
        }
      }
    }
  }
}
