.form-control {
  border: 1px solid var(--system-grey-2);
  border-radius: 6px;
}

.form-control:focus {
  box-shadow: none !important;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

// override react-toastify
.Toastify__toast {
  padding: 0px !important;
  border-radius: 16px;
  margin-bottom: 5px;
}
.Toastify__toast-container {
  top: 5rem;
  left: initial;
  width: min(300px, 100vw);
}
.Toastify__toast-body {
  padding: 0px;
}
