.button {
  --disable: rgba(11, 11, 12, 0.04);
  --disableColor: var(--app-black-30);

  --primary: var(--app-blue-50);
  --primaryColor: var(--app-white);
  --primaryHover: var(--app-blue-70);
  --primaryActive: var(--app-blue-100);

  --secondary: var(--app-blue-10);
  --secondaryColor: var(--app-blue-50);
  --secondaryHover: var(--app-blue-20);
  --secondaryHoverColor: var(--app-blue-70);
  --secondaryActive: var(--app-blue-30);

  --success: var(--app-green-50);
  --successColor: var(--app-white);
  --successHover: var(--app-green-70);
  --successActive: var(--app-green-100);

  --danger: var(--app-red-50);
  --dangerColor: var(--app-white);
  --dangerHover: var(--app-red-70);
  --dangerActive: var(--app-red-100);

  --link: transparent;
  --linkColor: var(--app-blue-50);
  --linkHover: var(--app-blue-10);
  --linkActive: var(--app-blue-20);
  --linkDisabled: transparent;
  --linkDisabledColor: var(--app-black-30);

  --lightLink: transparent;
  --lightLinkColor: var(--app-white);
  --lightLinkHover: rgba(255, 255, 255, 0.08);
  --lightLinkActive: rgba(255, 255, 255, 0.16);
  --lightLinkDisabled: transparent;
  --lightLinkDisabledColor: rgba(255, 255, 255, 0.24);

  --white: var(--app-white);
  --whiteColor: var(--app-blue-50);
  --whiteHover: var(--app-black-20);
  --whiteHoverColor: var(--app-blue-70);
  --whiteActive: var(--app-black-30);
  --whiteActiveColor: var(--app-blue-70);
  --whiteFocus: var(--app-black-20);
  --whiteFocusColor: var(--app-blue-70);
  --whiteDisabled: rgba(255, 255, 255, 0.08);
  --whiteDisabledColor: rgba(255, 255, 255, 0.24);

  --light: rgba(255, 255, 255, 0.08);
  --lightColor: var(--app-white);
  --lightHover: rgba(255, 255, 255, 0.16);
  --lightHoverColor: var(--app-white);
  --lightActive: rgba(255, 255, 255, 0.24);
  --lightActiveColor: var(--app-white);
  --lightFocus: rgba(255, 255, 255, 0.16);
  --lightFocusColor: var(--app-white);
  --lightDisabled: rgba(255, 255, 255, 0.08);
  --lightDisabledColor: rgba(255, 255, 255, 0.24);

  --grey: rgba(11, 11, 12, 0.04);
  --greyColor: var(--app-black-70);
  --greyHover: rgba(11, 11, 12, 0.08);
  --greyActive: rgba(11, 11, 12, 0.16);
  --greyDisabled: rgba(11, 11, 12, 0.16);
  --greyDisabledColor: rgba(11, 11, 12, 0.16);

  --greyLink: transparent;
  --greyLinkColor: var(--app-black-70);
  --greyLinkHover: rgba(11, 11, 12, 0.04);
  --greyLinkActive: rgba(11, 11, 12, 0.08);
  --greyLinkDisabled: transparent;
  --greyLinkDisabledColor: var(--app-black-30);


  outline: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 2px solid transparent;
  font-family: "Inter", sans-serif;
  padding: 14px 22px;
  border-radius: 12px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s color ease, 0.2s border-color ease, 0.2s background-color ease;

  > :nth-child(2) {
    margin-left: 8px;
  }

  > :nth-child(3) {
    margin-left: 8px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &.small {
    padding: 6px 14px;
    font-size: 14px;
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--disableColor);
    border-color: transparent;
    background-color: var(--disable);

    svg {
      fill: var(--disableColor);
    }

    &:hover {
      color: var(--disableColor);
      border-color: transparent;
      background-color: var(--disable);

      svg {
        fill: var(--disableColor);
      }
    }

    &:active {
      color: var(--disableColor);
      border-color: transparent;
      background-color: var(--disable);

      svg {
        fill: var(--disableColor);
      }
    }
  }
}

.primary {
  color: var(--primaryColor);
  border-color: var(--primary);
  background-color: var(--primary);

  svg {
    fill: var(--primaryColor);
  }

  &:hover {
    border-color: var(--primaryHover);
    background-color: var(--primaryHover);
  }

  &:active {
    border-color: var(--primaryActive);
    background-color: var(--primaryActive);
  }

  &:focus:not(:active) {
    border-color: var(--primaryActive);
    background-color: var(--primaryHover);
  }
}

.secondary {
  color: var(--secondaryColor);
  border-color: var(--secondary);
  background-color: var(--secondary);

  svg {
    fill: var(--secondaryColor);
  }

  &:hover {
    color: var(--secondaryHoverColor);
    border-color: var(--secondaryHover);
    background-color: var(--secondaryHover);

    svg {
      fill: var(--secondaryHoverColor)
    }
  }

  &:active {
    color: var(--secondaryHoverColor);
    border-color: var(--secondaryActive);
    background-color: var(--secondaryActive);


    svg {
      fill: var(--secondaryHoverColor)
    }
  }

  &:focus:not(:active) {
    color: var(--secondaryHoverColor);
    border-color: var(--secondaryHoverColor);
    background-color: var(--secondaryHover);

    svg {
      fill: var(--secondaryHoverColor)
    }
  }
}

.success {
  color: var(--successColor);
  border-color: var(--success);
  background-color: var(--success);

  svg {
    fill: var(--successColor);
  }

  &:hover {
    border-color: var(--successHover);
    background-color: var(--successHover);
  }

  &:active {
    border-color: var(--successActive);
    background-color: var(--successActive);
  }

  &:focus:not(:active) {
    border-color: var(--successActive);
    background-color: var(--successHover);
  }
}

.danger {
  color: var(--dangerColor);
  border-color: var(--danger);
  background-color: var(--danger);

  svg {
    fill: var(--dangerColor);
  }

  &:hover {
    border-color: var(--dangerHover);
    background-color: var(--dangerHover);
  }

  &:active {
    border-color: var(--dangerActive);
    background-color: var(--dangerActive);
  }

  &:focus:not(:active) {
    border-color: var(--dangerActive);
    background-color: var(--dangerHover);
  }
}

.link {
  color: var(--linkColor);
  border-color: var(--link);
  background-color: var(--link);

  svg {
    fill: var(--linkColor);
  }

  &:hover {
    border-color: transparent;
    background-color: var(--linkHover);
  }

  &:active {
    border-color: transparent;
    background-color: var(--linkActive);
  }

  &:focus:not(:active) {
    border-color: var(--linkColor);
    background-color: var(--linkHover);
  }

  &.disabled {
    color: var(--linkDisabledColor);
    border-color: var(--linkDisabled);
    background-color: var(--linkDisabled);

    svg {
      fill: var(--linkDisabledColor);
    }

    &:hover {
      color: var(--linkDisabledColor);
      border-color: var(--linkDisabled);
      background-color: var(--linkDisabled);

      svg {
        fill: var(--linkDisabledColor);
      }
    }

    &:active {
      color: var(--linkDisabledColor);
      border-color: var(--linkDisabled);
      background-color: var(--linkDisabled);

      svg {
        fill: var(--linkDisabledColor);
      }
    }
  }
}

.white {
  color: var(--whiteColor);
  border-color: var(--white);
  background-color: var(--white);

  svg {
    fill: var(--whiteColor);
  }

  &:hover {
    color: var(--whiteHoverColor);
    border-color: var(--whiteHover);
    background-color: var(--whiteHover);

    svg {
      fill: var(--whiteHoverColor);
    }
  }

  &:active {
    color: var(--whiteActiveColor);
    border-color: var(--whiteActive);
    background-color: var(--whiteActive);

    svg {
      fill: var(--whiteActiveColor);
    }
  }

  &:focus:not(:active) {
    color: var(--whiteFocusColor);
    border-color: var(--whiteFocusColor);
    background-color: var(--whiteHover);

    svg {
      fill: var(--whiteFocusColor);
    }
  }

  &.disabled {
    color: var(--whiteDisabledColor);
    border-color: transparent;
    background-color: var(--whiteDisabled);

    svg {
      fill: var(--whiteDisabledColor);
    }

    &:hover {
      color: var(--whiteDisabledColor);
      border-color: transparent;
      background-color: var(--whiteDisabled);

      svg {
        fill: var(--whiteDisabledColor);
      }
    }

    &:active {
      color: var(--whiteDisabledColor);
      border-color: transparent;
      background-color: var(--whiteDisabled);

      svg {
        fill: var(--whiteDisabledColor);
      }
    }
  }
}

.light {
  color: var(--lightColor);
  border-color: transparent;
  background-color: var(--light);

  svg {
    fill: var(--lightColor);
  }

  &:hover {
    color: var(--lightHoverColor);
    border-color: transparent;
    background-color: var(--lightHover);

    svg {
      fill: var(--lightColor);
    }
  }

  &:active {
    color: var(--lightActiveColor);
    border-color: transparent;
    background-color: var(--lightActive);

    svg {
      fill: var(--lightColor);
    }
  }

  &:focus:not(:active) {
    color: var(--lightFocusColor);
    border-color: var(--lightFocusColor);
    background-color: var(--lightHover);

    svg {
      fill: var(--lightColor);
    }
  }

  &.disabled {
    color: var(--lightDisabledColor);
    border-color: transparent;
    background-color: var(--lightDisabled);

    svg {
      fill: var(--lightDisabledColor);
    }

    &:hover {
      color: var(--lightDisabledColor);
      border-color: transparent;
      background-color: var(--lightDisabled);

      svg {
        fill: var(--lightDisabledColor);
      }
    }

    &:active {
      color: var(--lightDisabledColor);
      border-color: transparent;
      background-color: var(--lightDisabled);

      svg {
        fill: var(--lightDisabledColor);
      }
    }
  }
}

.lightLink {
  color: var(--lightLinkColor);
  border-color: var(--lightLink);
  background-color: var(--lightLink);

  svg {
    fill: var(--lightLinkColor);
  }

  &:hover {
    border-color: transparent;
    background-color: var(--lightLinkHover);
  }

  &:active {
    border-color: transparent;
    background-color: var(--lightLinkActive);
  }

  &:focus:not(:active) {
    border-color: var(--lightLinkColor);
    background-color: var(--lightLinkHover);
  }

  &.disabled {
    color: var(--lightLinkDisabledColor);
    border-color: var(--lightLinkDisabled);
    background-color: var(--lightLinkDisabled);

    svg {
      fill: var(--lightLinkDisabledColor);
    }

    &:hover {
      color: var(--lightLinkDisabledColor);
      border-color: var(--lightLinkDisabled);
      background-color: var(--lightLinkDisabled);

      svg {
        fill: var(--lightLinkDisabledColor);
      }
    }

    &:active {
      color: var(--lightLinkDisabledColor);
      border-color: var(--lightLinkDisabled);
      background-color: var(--lightLinkDisabled);

      svg {
        fill: var(--lightLinkDisabledColor);
      }
    }
  }
}

.grey {
  color: var(--greyColor);
  border-color: transparent;
  background-color: var(--grey);

  svg {
    fill: var(--greyColor);
  }

  &:hover {
    border-color: transparent;
    background-color: var(--greyHover);
  }

  &:active {
    border-color: transparent;
    background-color: var(--greyActive);
  }

  &:focus:not(:active) {
    border-color: var(--greyColor);
    background-color: var(--greyHover);
  }
}

.greyLink {
  color: var(--greyLinkColor);
  border-color: transparent;
  background-color: var(--greyLink);

  svg {
    fill: var(--greyLinkColor);
  }

  &:hover {
    border-color: transparent;
    background-color: var(--greyLinkHover);
  }

  &:active {
    border-color: transparent;
    background-color: var(--greyLinkActive);
  }

  &:focus:not(:active) {
    border-color: var(--greyLinkColor);
    background-color: var(--greyLinkHover);
  }

  &.disabled {
    color: var(--greyLinkDisabledColor);
    border-color: transparent;
    background-color: var(--greyLink);

    svg {
      fill: var(--greyLinkDisabledColor);
    }

    &:hover {
      color: var(--greyLinkDisabledColor);
      border-color: transparent;
      background-color: var(--greyLink);

      svg {
        fill: var(--greyLinkDisabledColor);
      }
    }

    &:active {
      color: var(--greyLinkDisabledColor);
      border-color: transparent;
      background-color: var(--greyLink);

      svg {
        fill: var(--greyLinkDisabledColor);
      }
    }
  }
}

