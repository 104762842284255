.container {
  padding: 16px;
  width: 100%;

  border-radius: 16px;

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      margin-bottom: 6px;

      .icon {
        width: 14px;
        height: 14px;
        fill: white;
      }

      .closeIcon {
        width: 14px;
        height: 14px;
        fill: white;
      }

      > div {
        > * + * {
          margin-left: 8px;
        }

        display: flex;
        align-items: center;

        span {
          font-family: 'Inter', sans-serif;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }

    .message {
      font-family: 'Inter', sans-serif;
      font-size: 16px;
      line-height: 24px;

      margin: 0;

      color: #fff;
    }
  }
}

.success {
  background-color: var(--app-green-50);
  color: #fff;
}

.info {
  background-color: var(--app-blue-50);
  color: #fff;
}

.warning {
  background-color: var(--app-yellow-50);
  color: var(--app-black-70);

  .flex {
    .header {
      .icon {
        fill: var(--app-black-70);
      }

      .closeIcon {
        fill: var(--app-black-70);
      }
    }

    .message {
      color: var(--app-black-70);
    }
  }
}

.error {
  background-color: var(--app-red-50);
  color: #fff;
}
