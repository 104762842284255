.card {
  padding: 16px 16px;
  background: #E6F1FD;
  text-align: center;
}


.cardTag {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  color: var(--app-blue-50);
  display: inline-block;
  margin-bottom: 16px;
}

.cardTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  font-family: "Inter", sans-serif;
  color: var(--app-black-100);
  margin-bottom: 0;
}

.cardDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  color: var(--app-black-100);
  margin-bottom: 16px;

  span {
    color: var(--app-blue-50);
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.bottomText {
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  font-family: "Inter", sans-serif;
  color: var(--app-black-100);
  margin-top: 40px;
  margin-bottom: 0;
}

.walletActions {
  gap: 16px;
  display: flex;
  margin-top: 40px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .claimBox {
    gap: 16px;
    width: 100%;
    display: flex;
    justify-content: center;

    .walletInput {
      width: 100%;
      max-width: 502px;
      text-align: left;
    }

    .claimButton {
      flex-shrink: 0;
    }
  }
}

.walletConnect {
  gap: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.notice {
  gap: 8px;
  display: flex;
  font-size: 14px;
  align-items: center;
  text-align: left;
  font-family: "Inter", sans-serif;
  color: var(--app-red-100);
  background-color: var(--app-red-10);
  padding: 8px 16px;
  border-radius: 12px;
  margin: 0;

  svg {
    flex-shrink: 0;
  }

  &.warning {
    color: var(--app-yellow-100);
    background-color: var(--app-yellow-10);
  }
}

.button {
  color: var(--app-white) !important;
  background-color: var(--app-blue-50) !important;
  outline: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  border: 2px solid var(--app-blue-50) !important;
  font-family: "Inter", sans-serif !important;
  padding: 14px 22px !important;
  border-radius: 12px !important;
  box-sizing: border-box !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  transform: none !important;
  box-shadow: none !important;
  transition: 0.2s color ease, 0.2s border-color ease, 0.2s background-color ease !important;

  &:hover {
    border-color: var(--app-blue-70) !important;
    background-color: var(--app-blue-70) !important;
  }

  &:active {
    border-color: var(--app-blue-100) !important;
    background-color: var(--app-blue-100) !important;
  }

  &:focus:not(:active) {
    border-color: var(--app-blue-100) !important;
    background-color: var(--app-blue-70) !important;
  }
}

@media screen and (max-width: 820px) {
  .walletActions {
    .claimBox {
      flex-direction: column;

      .walletInput {
        max-width: initial;
      }
    }
  }
}
